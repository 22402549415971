/**
 * Blog Landing Page
 */

import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../layouts";
import HeroGeneral from "../components/heroes/general";
import ArticleCard from "../components/article-card";
import Heading from "../components/heading";

const BlogPage = ({ data }) => (
  <Layout title="Blog">
    <HeroGeneral title="Blog" />

    <div className="flex md:flex-wrap flex-col md:flex-row max-w-screen-desktop mb-8 md:mb-16 mx-auto px-6">
      {data.allSanityPost.edges.length > 0 ? (
        data.allSanityPost.edges.map(edge => {
          const { node } = edge;

          return (
            <div className="w-1/3 p-4" key={node.id}>
              <ArticleCard
                category="Mental Health"
                title={node.title}
                path={`/blog/${node.slug.current}`}
                featureImage={node.featureImage.asset.fluid}
              />
            </div>
          );
        })
      ) : (
        <div>
          <Heading>No Articles Published</Heading>
        </div>
      )}
    </div>
  </Layout>
);

export const query = graphql`
  query {
    allSanityPost {
      edges {
        node {
          title
          id
          slug {
            current
          }
          featureImage {
            asset {
              fluid(maxWidth: 800) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;

BlogPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default BlogPage;
